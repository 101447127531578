import React, { Component } from "react";
import { Link } from "gatsby";
import PropTypes from "prop-types";
import _ from "lodash";

class Paginator extends Component {
  previousPageLink() {
    const { paginator, prefix, themeStyles } = this.props;
    const { currentPage } = paginator;

    if (currentPage == 1) {
      return (
        <li className={themeStyles.disabled}>
          <Link to="#" onClick={(e) => e.preventDefault()}>
            &laquo;
          </Link>
        </li>
      );
    } else {
      let previousPage = currentPage - 1;
      if (previousPage == 1) {
        return (
          <li>
            <Link to={`/${prefix}${this.props.search}`}>&laquo;</Link>
          </li>
        );
      } else {
        return (
          <li>
            <Link to={`/${prefix}/${previousPage}${this.props.search}`}>&laquo;</Link>
          </li>
        );
      }
    }
  }

  nextPageLink() {
    const { paginator, prefix, themeStyles } = this.props;
    const { currentPage, lastPage } = paginator;

    if (currentPage == lastPage) {
      return (
        <li className={themeStyles.disabled}>
          <Link to="#" onClick={(e) => e.preventDefault()}>
            &raquo;
          </Link>
        </li>
      );
    } else {
      const nextPage = currentPage + 1;
      return (
        <li>
          <Link to={`/${prefix}/${nextPage}${this.props.search}`}>&raquo;</Link>
        </li>
      );
    }
  }

  paginatorConditions() {
    const conditions = [false, false, false, false, false];
    const { paginator } = this.props;
    const { currentPage, lastPage } = paginator;

    if (lastPage <= 4) {
      conditions[0] = true;
    }

    if (
      lastPage > 4 &&
      (currentPage == 1 ||
        currentPage == 2 ||
        currentPage == lastPage ||
        currentPage == lastPage - 1)
    ) {
      conditions[1] = true;
    }

    if (lastPage > 4 && currentPage == lastPage - 2 && currentPage != 1 && currentPage != 2) {
      conditions[2] = true;
    }

    if (lastPage > 4 && currentPage == lastPage - 3 && currentPage != 1 && currentPage != 2) {
      conditions[3] = true;
    }

    if (!conditions[0] && !conditions[1] && !conditions[2] && !conditions[3]) {
      conditions[4] = true;
    }

    return conditions;
  }

  prepareLink(prefix, page, text = "") {
    let path = `/${prefix}`;
    if (page > 1) {
      path += `/${page}`;
    }

    if (this.props.search) {
      path += this.props.search;
    }

    if (page != this.props.paginator.currentPage) {
      return <Link to={path}>{text ? text : page}</Link>;
    } else {
      return (
        <Link to="#" onClick={(e) => e.preventDefault()}>
          {text ? text : page}
        </Link>
      );
    }
  }

  render() {
    const { paginator, prefix, themeStyles } = this.props;
    const { currentPage, lastPage, total } = paginator;
    const paginatorConditions = this.paginatorConditions();

    if (total > 0) {
      return (
        <div className={`${themeStyles.paginationC}`}>
          <ul className={themeStyles.pagination}>
            {this.previousPageLink()}

            {(() => {
              if (paginatorConditions[0]) {
                return _.range(1, lastPage + 1).map((n) => {
                  return (
                    <li
                      className={
                        currentPage == n ? `${themeStyles.disabled}` : ""
                      }
                      key={n}
                    >
                      {this.prepareLink(prefix, n)}
                    </li>
                  );
                });
              }
            })()}

            {(() => {
              if (paginatorConditions[1]) {
                return _.range(1, 3).map((n) => {
                  return (
                    <li
                      className={
                        currentPage == n ? `${themeStyles.disabled}` : ""
                      }
                      key={n}
                    >
                      {this.prepareLink(prefix, n)}
                    </li>
                  );
                });
              }
            })()}

            {(() => {
              if (paginatorConditions[1]) {
                return (
                  <li className={themeStyles.disabled}>
                    <a href="#" onClick={(e) => e.preventDefault()}>
                      ..
                    </a>
                  </li>
                );
              }
            })()}

            {(() => {
              if (paginatorConditions[1]) {
                return _.range(lastPage - 1, lastPage + 1).map((n) => {
                  return (
                    <li
                      className={
                        currentPage == n ? `${themeStyles.disabled}` : ""
                      }
                      key={n}
                    >
                      {this.prepareLink(prefix, n)}
                    </li>
                  );
                });
              }
            })()}

            {(() => {
              if (paginatorConditions[2]) {
                return <li>{this.prepareLink(prefix, 1)}</li>;
              }
            })()}

            {(() => {
              if (paginatorConditions[2]) {
                return (
                  <li className={themeStyles.disabled}>
                    <a href="#">..</a>
                  </li>
                );
              }
            })()}

            {(() => {
              if (paginatorConditions[2]) {
                return _.range(currentPage, lastPage + 1).map((n) => {
                  return (
                    <li
                      className={
                        currentPage == n ? `${themeStyles.disabled}` : ""
                      }
                      key={n}
                    >
                      {this.prepareLink(prefix, n)}
                    </li>
                  );
                });
              }
            })()}

            {(() => {
              if (paginatorConditions[3]) {
                return <li>{this.prepareLink(prefix, 1)}</li>;
              }
            })()}

            {(() => {
              if (paginatorConditions[3]) {
                return (
                  <li className={themeStyles.disabled}>
                    <a href="#">..</a>
                  </li>
                );
              }
            })()}

            {(() => {
              if (paginatorConditions[3]) {
                return _.range(currentPage, lastPage - 1).map((n) => {
                  return (
                    <li
                      className={
                        currentPage == n ? `${themeStyles.disabled}` : ""
                      }
                      key={n}
                    >
                      {this.prepareLink(prefix, n)}
                    </li>
                  );
                });
              }
            })()}

            {(() => {
              if (paginatorConditions[3]) {
                return (
                  <li className={themeStyles.disabled}>
                    <a href="#">..</a>
                  </li>
                );
              }
            })()}

            {(() => {
              if (paginatorConditions[3]) {
                return <li>{this.prepareLink(prefix, lastPage)}</li>;
              }
            })()}

            {(() => {
              if (paginatorConditions[4]) {
                return <li>{this.prepareLink(prefix, 1)}</li>;
              }
            })()}

            {(() => {
              if (paginatorConditions[4]) {
                return (
                  <li className={themeStyles.disabled}>
                    <a href="#">..</a>
                  </li>
                );
              }
            })()}

            {(() => {
              if (paginatorConditions[4]) {
                return (
                  <li className={themeStyles.disabled}>
                    {this.prepareLink(prefix, currentPage)}
                  </li>
                );
              }
            })()}

            {(() => {
              if (paginatorConditions[4]) {
                return <li>{this.prepareLink(prefix, currentPage + 1)}</li>;
              }
            })()}

            {(() => {
              if (paginatorConditions[4]) {
                return (
                  <li className={themeStyles.disabled}>
                    <a href="#">..</a>
                  </li>
                );
              }
            })()}

            {(() => {
              if (paginatorConditions[4]) {
                return _.range(lastPage - 1, lastPage + 1).map((n) => {
                  return (
                    <li
                      className={
                        currentPage == n ? `${themeStyles.disabled}` : ""
                      }
                      key={n}
                    >
                      {this.prepareLink(prefix, n)}
                    </li>
                  );
                });
              }
            })()}

            {this.nextPageLink()}
          </ul>
        </div>
      );
    }

    return null;
  }
}

Paginator.propTypes = {
  paginator: PropTypes.object.isRequired,
  prefix: PropTypes.string.isRequired,
  search: PropTypes.string,
  themeStyles: PropTypes.object,
};

export default Paginator;
