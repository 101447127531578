exports.createPaginatedData = (items, limit, page) => {
  const data = items.slice((page - 1) * limit, page * limit);

  const total = items.length;
  const count = data.length;
  const currentPage = page;
  const firstItem = limit * (page - 1) + 1;
  const lastItem = limit * (page - 1) + count;
  const hasMorePages = lastItem < total;
  const lastPage = Math.ceil(total / limit);
  const perPage = limit;
  const paginatorInfo = {
    count,
    currentPage,
    firstItem,
    hasMorePages,
    lastItem,
    lastPage,
    perPage,
    total,
  };

  return { data, paginatorInfo };
};

exports.createSlug = (name) => {
  name = name.toLowerCase();
  name = name.replace(/\s/g, "-");

  return name;
};

exports.itemsPerPage = 12;

exports.sortRecords = (records, sort_column, sort_order) => {
  if (sort_column == "created_at" || sort_column == "updated_at") {
    records.sort(function compare(record1, record2) {
      let date1 = new Date(record1[sort_column]);
      let date2 = new Date(record2[sort_column]);
      return sort_order == "asc" ? date1 - date2 : date2 - date1;
    });
  } else {
    records = _.orderBy(
      records,
      [(el) => el[sort_column] || ""],
      [sort_order]
    );
  }

  return records;
};
